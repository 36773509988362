import React, { useEffect, useState } from "react";
import { Button, Menu, Modal, Input, Form } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Projects from "./Projects";
import Settings from "./Settings";
import Pricing from "./Pricing";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";
import RefundPolicy from "./RefundPolicy";
import SingleProjectPage from "./SingleProjectPage";
import NewProject from "./NewProject";
import VideoModal from "../components/VideoModal";
import logo from "../assets/cb menu logo.svg";
import icon1 from "../assets/navigator1.svg";
import icon2 from "../assets/navigator2.svg";
import icon3 from "../assets/navigator3.svg";
import icon4 from "../assets/navigator4.svg";
import icon5 from "../assets/navigator5.svg";
import icon6 from "../assets/navigator6.svg";
import icon7 from "../assets/navigator7.svg";
import icon8 from "../assets/navigator8.svg";
import icon9 from "../assets/navigator9.svg";
import icon10 from "../assets/navigator10.svg";
import icon11 from "../assets/navigator11.svg";
import icon12 from "../assets/navigator12.svg";
import icon13 from "../assets/video-icon.png";
import "./styles/Navigator.css";
import { removeAuthToken, setLoggedIn } from "../redux/AuthToken/Action";
import { API_SUBMIT_HELP_FORM } from "../api/api_navigator";
import FeedbackForms from "../components/FeedbackForms";
import SerialCode from "../components/SerialCode";

const Navigator = ({
  route,
  navigateToProjectPage,
  setnavigateToProjectPage,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSerialCodeModalOpen, setisSerialCodeModalOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState("projects");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Type_of_modal, setType_of_modal] = useState("");
  const [form] = Form.useForm();
  const [isIframeVisible, setIframeVisible] = useState(false);

  const modals = ["report", "feedback", "contact", "apply_affiliate"];
  const { token, isLoggedIn } = useSelector((state) => state.authToken);

  const handleToggleIframe = (isVisible) => {
    setIframeVisible(isVisible);
  };

  const showModal = (type) => () => {
    setType_of_modal(type);
    setIsModalOpen(true);
  };

  const logoutHandler = () => {
    dispatch(setLoggedIn(false));
    dispatch(removeAuthToken());
    navigate("/");
  };

  const onClick = (e) => {
    setSelectedKey(e.key);
    if (e.key === "projects-page") {
      const project_id_from_url =
        window.location.href.match(/project\/(\d+)/)[1];
      navigate(`/project/${project_id_from_url}`);
    } else if (modals.includes(e.key)) {
      showModal(e.key)();
      setSelectedKey("projects");
      return;
    } else if (isLoggedIn) {
      navigate(`/${e.key}`);
    } else {
      navigate("/account");
    }
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/account");
    }
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    if (navigateToProjectPage && route === "projects-page") {
      setnavigateToProjectPage(false);
      setSelectedKey("projects-page");
    } else {
      setSelectedKey(route);
    }
  }, [navigateToProjectPage, route]);

  return (
    <div style={{ height: "110%" }}>
      <div
        style={{
          width: windowWidth < 1200 ? 48 : 256,
          backgroundColor: "#007EB2",
          color: "white",
          flexShrink: 0,
          position: "fixed",
          height: "110vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {windowWidth > 1200 && (
          <div
            style={{
              textAlign: "center",
              padding: "16px",
              marginBottom: "20px",
              marginTop: "15px",
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{ width: "90%", height: "auto" }}
            />
          </div>
        )}

        <Menu
          onClick={onClick}
          style={{
            height: "110vh",
            backgroundColor: "#007EB2",
            color: "white",
            flex: 1,
          }}
          inlineCollapsed={windowWidth < 1200}
          className="menu"
          defaultSelectedKeys={[route]}
          mode="inline"
        >
          <div
            onClick={() => handleToggleIframe(true)}
            className="navigator_modals"
          >
            <img src={icon13} /> {windowWidth > 1200 && "Watch Demo"}
          </div>

          <Menu.Item key="projects" icon={<img src={icon6} />}>
            {" "}
            My Campaigns{" "}
          </Menu.Item>
          <Menu.Item key="pricing" icon={<img src={icon7} />}>
            {" "}
            Plan Manager{" "}
          </Menu.Item>
          <Menu.Item key="settings" icon={<img src={icon5} />}>
            {" "}
            Account Settings{" "}
          </Menu.Item>

          <Menu.Divider style={{ borderColor: "white" }} />

          <div
            onClick={() => setisSerialCodeModalOpen(!isSerialCodeModalOpen)}
            className="navigator_modals"
          >
            {" "}
            <img src={icon12} /> {windowWidth > 1200 && "Redeem serial code"}{" "}
          </div>
          <div onClick={showModal("report")} className="navigator_modals">
            {" "}
            <img src={icon8} /> {windowWidth > 1200 && " Report a Bug"}{" "}
          </div>
          <div onClick={showModal("feedback")} className="navigator_modals">
            {" "}
            <img src={icon9} /> {windowWidth > 1200 && " Leave Feedback"}{" "}
          </div>
          <div onClick={showModal("contact")} className="navigator_modals">
            {" "}
            <img src={icon10} /> {windowWidth > 1200 && " Support"}{" "}
          </div>
          <div
            onClick={showModal("apply_affiliate")}
            className="navigator_modals"
          >
            {" "}
            <img src={icon11} /> {windowWidth > 1200 && " Affiliate Program"}{" "}
          </div>

          <Menu.Divider style={{ borderColor: "white" }} />

          <Menu.Item key="privacyPolicy" icon={<img src={icon3} />}>
            {" "}
            Privacy Policy{" "}
          </Menu.Item>
          <Menu.Item key="termsOfService" icon={<img src={icon4} />}>
            {" "}
            Terms of Service{" "}
          </Menu.Item>
          <Menu.Item key="refundPolicy" icon={<img src={icon2} />}>
            {" "}
            Refund Policy{" "}
          </Menu.Item>
        </Menu>
        <div
          style={{ marginTop: "auto", backgroundColor: "#007EB2" }}
          className="text-start ms-2 ms-xxl-0 mb-3 logoutButton"
        >
          <Button
            className="logout_btn"
            icon={<img src={icon1} />}
            style={{ color: "white" }}
            onClick={logoutHandler}
          >
            {windowWidth > 1200 && "Logout"}
          </Button>
        </div>
      </div>

      <div style={{ flex: 1, marginLeft: windowWidth < 1200 ? 48 : 256 }}>
        {selectedKey === "projects" && <Projects />}
        {selectedKey === "projects-page" && <SingleProjectPage />}
        {selectedKey === "new-project" && <NewProject />}
        {selectedKey === "settings" && <Settings />}
        {selectedKey === "pricing" && <Pricing />}
        {selectedKey === "privacyPolicy" && <PrivacyPolicy />}
        {selectedKey === "termsOfService" && <TermsOfService />}
        {selectedKey === "refundPolicy" && <RefundPolicy />}
      </div>
      <VideoModal
        videoUrl="https://www.youtube.com/embed/R1mQ7Bn2RFk?si=RO7hVBVB4gBs36xa"
        isIframeVisible={isIframeVisible}
        onToggleIframe={handleToggleIframe}
      />
      {isModalOpen && (
        <FeedbackForms
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          typeOfModal={Type_of_modal}
        />
      )}
      {isSerialCodeModalOpen && (
        <SerialCode
          setisSerialCodeModalOpen={setisSerialCodeModalOpen}
          isSerialCodeModalOpen={isSerialCodeModalOpen}
        />
      )}
    </div>
  );
};

export default Navigator;

import React, { useEffect, useState } from "react";
import styles from "./styles/Home.module.css";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import VideoModal from "../components/VideoModal";
import FeatureComponent from "../components/FeatureComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import home_video from "../assets/home_gif.gif"; // Check this path
import home_main_review1 from "../assets/home-main-review1.png";
import home_main_review2 from "../assets/home-main-review2.png";
import home_image_1 from "../assets/home_image_1.png";
import home_image_2 from "../assets/home_image_2.png";
import home_image_3 from "../assets/home_image_3.png";
import home_image_4 from "../assets/home_image_4.png";
import licon1 from "../assets/listicon1.svg";
import licon2 from "../assets/listicon2.svg";
import licon3 from "../assets/listicon3.svg";
import { FAQ, TESTIMONIALS } from "../lessUse/HomePageComponents";
import Footer from "../components/Footer";

const Home = () => {
  const [visibleItems, setVisibleItems] = useState(4);
  const { token, isLoggedIn } = useSelector((state) => state.authToken);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [isIframeVisible, setIframeVisible] = useState(false);

  const handleToggleIframe = (isVisible) => {
    setIframeVisible(isVisible);
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);

      // If window width is greater than 1200, show all testimonials
      if (window.innerWidth > 992) {
        setVisibleItems(TESTIMONIALS.length);
      } else {
        setVisibleItems(4); // Otherwise, show only 4 testimonials
      }
    };

    handleResize(); // Call this function initially to set the correct state
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLoadMore = () => {
    setVisibleItems(TESTIMONIALS.length); // Show all items on load more click
  };
  useEffect(() => {}, []);
  const navigate = useNavigate();
  return (
    <div className="overflow-x-hidden bg-white">
      <Helmet>
        <title>Personalized Video Prospecting | Scale Sales Outreach</title>
      </Helmet>
      <div className="row text-center">
        <Navbar />
      </div>
      <div
        className={`row ${styles.homeContainer} m-0 justify-content-center px-2`}
      >
        <div className="col-3 d-none d-xl-block align-self-center">
          <img src={home_main_review1} className={"w-100"} />
        </div>
        <div
          className={`col-12 col-xl-5 d-flex flex-column justify-content-center align-items-center ${styles.homeText}`}
        >
          <img
            src={home_video}
            alt="Personalized video prospecting tool for scaling sales outreach and booking meetings."
            className={styles.home_page_image_1}
            name="Personalized-Video-Prospecting-At-Scale-1"
          />
        </div>
        <div className="col-3 d-none d-xl-block align-self-center">
          <img src={home_main_review2} className={"w-100"} />
        </div>
      </div>
      <div className="row m-0 justify-content-center px-2">
        <div className="row m-0 justify-content-center">
          <div className="col-12 p-0 text-center mt-4  mt-xxl-5 mb-4">
            <h1
              style={{
                fontSize: windowWidth < 768 ? "45px" : "60px",
                fontWeight: 600,
                textAlign: "center",
                width: "100%",
              }}
            >
              Personalized Video Prospecting at Scale{" "}
            </h1>
          </div>
          <div className="col-12 col-md-6 p-0 text-center mb-4">
            <span className={styles.homeSectionPTag}>
              Generate personalized sales videos at scale and book 4X more
              meetings
            </span>
          </div>

          <div className="col-12 text-center p-0">
            <button
              className={styles.home_page_btn_outlined}
              onClick={() => handleToggleIframe(true)}
            >
              Watch Demo
            </button>
            <button
              className={styles.home_page_btn_filled}
              onClick={() => navigate("/account")}
            >
              TRY FOR FREE
            </button>
            <VideoModal
              videoUrl="https://www.youtube.com/embed/R1mQ7Bn2RFk?si=RO7hVBVB4gBs36xa"
              isIframeVisible={isIframeVisible}
              onToggleIframe={handleToggleIframe}
            />
          </div>

          <div className="col-10 col-md-6 p-0 text-center my-4">
            <span
              className={`${styles.homeSectionPTag} ${styles.homeSectionPTagLight}`}
            >
              &#10003; No credit card required
              <br className={windowWidth < 768 ? "d-block" : "d-none"} />
              <span className={styles.checkmarkSpacing}></span>
              &#10003; No software to install
            </span>
          </div>
        </div>
        <div className="col-12 p-0 text-center mt-5">
          <span className={styles.homeTextSpan1}>
            Create 100s of outreach videos{" "}
          </span>
          <span className={styles.homeTextSpan2}>
            in minutes, <br className="d-none d-lg-block" /> not hours.
            <div className="mt-4">
              <h2 style={{ fontWeight: 600, fontSize: "32px" }}>
                {" "}
                Features of Cyber Visionaries’ Video Prospecting Tool{" "}
              </h2>
            </div>
          </span>
        </div>

        <div className="col-12 p-0 text-center mt-3">
          <span className={styles.homeSectionPTagSmall}>
            Grab the attention of your potential clients with engaging messages
            personalized to their unique needs, while saving your time and
            resources
          </span>
        </div>

        <div className="col-12 p-0">
          <div className="row m-0 justify-content-evenly mb-5 mt-3 p-0">
            <div className={`col-11 col-lg-3 mb-2 mb-lg-0 ${styles.cardBody}`}>
              <img
                src={home_image_2}
                alt="Dynamic business proposals with personalized videos for enhanced sales engagement."
                name="Personalized-Video-Prospecting-At-Scale-2"
                className={`${styles.cardImage}`}
              />
              <h3 className={`${styles.cardHeading}`}>
                Steps to Create Sales Videos
              </h3>
              <p className={`${styles.cardDescription} text-start`}>
                {" "}
                Easily combine your videos with prospects' websites:
                <ul className="p-0">
                  <li>
                    <img src={licon3} />
                    Enter the website URLs or upload a CSV file
                  </li>
                  <li>
                    <img src={licon2} />
                    Add your recorded video
                  </li>
                  <li>
                    <img src={licon1} />
                    Generate personalized outreach videos in bulk
                  </li>
                </ul>
              </p>
            </div>
            <div className={`col-11 col-lg-3 mb-2 mb-lg-0 ${styles.cardBody}`}>
              <img
                src={home_image_3}
                alt="Easily book meetings through personalized video prospecting software."
                name="Personalized-Video-Prospecting-At-Scale-3"
                className={`${styles.cardImage}`}
              />
              <p className={`${styles.cardHeading}`}>Bulk Video Editor</p>
              <p className={`${styles.cardDescription}`}>
                {" "}
                Tweak your uploads and automated videos with our dedicated
                editor, integrated with all of your campaigns.
              </p>
            </div>
            <div className={`col-11 col-lg-3 mb-2 mb-lg-0 ${styles.cardBody}`}>
              <img
                src={home_image_4}
                alt="Seamlessly blend videos and web content for engaging sales proposals."
                name="Personalized-Video-Prospecting-At-Scale-4"
                className={`${styles.cardImage}`}
              />
              <p className={`${styles.cardHeading}`}>
                Streamlined for Automation
              </p>
              <p className={`${styles.cardDescription}`}>
                {" "}
                Our tool allows uploading videos directly to Google Drive for
                quick use with your outreach campaigns and simple integration
                with automation tools like Make, Zappier and more!
              </p>
            </div>
          </div>
        </div>
        <div className="text-center">
          <h2 style={{ fontSize: "42px", fontWeight: 600 }}>
            Real Results: Book{" "}
            <span style={{ color: "rgba(0, 126, 178, 1)" }}>4X More</span>{" "}
            Meetings with Videos
          </h2>
        </div>
        <div className="text-center mt-2">
          <span className={styles.homeTextSpan1}>Loved By </span>
          <span className={styles.homeTextSpan2}>users,</span>
          <span className={styles.homeTextSpan1}> optimized for </span>
          <span className={styles.homeTextSpan2}>professionals</span>
        </div>
        <div className="col-10 col-md-7 p-0 mt-3 text-center">
          <span className={styles.homeSectionPTagSmall}>
             Automate the creation of personalized videos for reaching out to
            prospects at scale and save valuable time on repetitive tasks. You
            can also export your videos to Google Drive and set up a campaign
            with your favorite outreach or automation tool.
          </span>
        </div>

        <div className=" text-center mt-5">
          <h2 style={{ fontWeight: 600, fontSize: "42px" }}>
            {" "}
            Why Choose Personalized Video for Your Sales Strategy?{" "}
          </h2>
        </div>

        <div className="col-12 p-0 text-center mt-2">
          <h3>
            <span
              style={{
                color: "rgba(0, 126, 178, 1)",
                fontWeight: 600,
                fontSize: "32px",
              }}
            >
              Examples of Successful Video Prospecting Campaigns
            </span>
          </h3>
        </div>
      </div>
      <div className="row m-0 px-2 p-lg-4" id="testimonials">
        {TESTIMONIALS.slice(0, visibleItems).map((item) => (
          <FeatureComponent key={item.id} item={item} />
        ))}

        {/* Conditionally render the Load More button */}
        {visibleItems < TESTIMONIALS.length && windowWidth < 992 && (
          <div className="col-12 text-center">
            <button
              onClick={handleLoadMore}
              style={{ width: "90%", height: "45px" }}
              className={`mt-4 m-0 ${styles.home_page_btn_outlined}`}
            >
              Load More
            </button>
          </div>
        )}
      </div>
      {/* <div className="row m-0 px-2 p-lg-4" id="testimonials">
        {TESTIMONIALS.map((item)=><FeatureComponent item={item}/>)}
      </div> */}

      <div className="row m-0 mt-5 px-2 p-lg-5 mb-3 mb-lg-0 " id={"home_faq"}>
        <FAQ navigate={navigate} isLoggedIn={isLoggedIn} />
      </div>

      <div className={`row m-0 px-2 py-3 p-lg-4 ${styles.footer_component}`}>
        <Footer />
      </div>
    </div>
  );
};

export default Home;

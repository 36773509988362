import React, { useState, useEffect } from "react";
import styles from "./styles/VideoModal.module.css";

const VideoModal = ({ videoUrl, isIframeVisible, onToggleIframe }) => {
  const handleCloseIframe = () => {
    onToggleIframe(false);
  };

  const handleClickOutside = (event) => {
    if (event.target === event.currentTarget) {
      handleCloseIframe();
    }
  };

  useEffect(() => {
    const handleEscapePress = (event) => {
      if (event.key === "Escape") {
        handleCloseIframe();
      }
    };

    document.addEventListener("keydown", handleEscapePress);

    return () => {
      document.removeEventListener("keydown", handleEscapePress);
    };
  }, []);

  return (
    <>
      {isIframeVisible && (
        <div className={styles.overlay} onClick={handleClickOutside}>
          <div className={styles.iframeContainer}>
            <div className={styles.closeButton} onClick={handleCloseIframe}>
              &times;
            </div>
            <iframe
              width="860"
              height="483"
              src={videoUrl}
              title="Demo Video"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default VideoModal;
